'use client';

import { useRouter } from 'next/navigation';
import { Button, Card, CardBody } from '@nextui-org/react';
import { fetchUser } from '@/redux/thunk/authThunk';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/redux/store';
import { useEffect } from 'react';

export default function NotFound() {
  const router = useRouter(); // Use Next.js navigation
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {;
    dispatch(fetchUser());
  }, []);
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100 p-4">
      <Card className="w-full max-w-[600px] rounded-2xl shadow-xl bg-white">
        <CardBody className="p-8 text-center">
          <h2 className="text-body-xl">Oops! Page Not Found</h2>
          <div className="text-body-md text-light-base-secondary pt-5">
            Looks like you've taken a wrong turn. The page you’re looking for
            doesn’t exist or may have been moved.
          </div>

          <div className="pt-8 flex flex-col md:flex-row w-full gap-4 justify-center">
            <div className="w-full md:w-1/2">
              <Button
                className="w-full"
                radius="sm"
                color="primary"
                variant="solid"
                onClick={() => router.push('/')} // Navigate without refresh
              >
                Go Back to Dashboard
              </Button>
            </div>
            <div className="w-full md:w-1/2">
              <a
                href="https://www.zynoff.com/contact-us/"
                target="_blank"
                rel="noopener noreferrer"
                className="w-full"
              >
                <Button
                  className="w-full"
                  radius="sm"
                  color="primary"
                  variant="bordered"
                >
                  Need Help? Contact Support
                </Button>
              </a>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
