'use client';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

export const Firebase = async () => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      const firebaseConfig = {
        apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
        authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
        storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
        measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
      };

      // Initialize Firebase
      // const analytics = getAnalytics(app);
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);
      try {
        return await getToken(messaging, {
          vapidKey:
            'BOXWYTk-sVdGvjKPlf6tZmt-Vc97472CGbW8vKI0__JUIG2-OuREp92mAN3DMhbDHj8b56DquOAIqmJm1-fhH_s',
        });
      } catch (error) {
        console.error('Error retrieving token:', error);
        return null;
      }
    } else {
      console.log('Unable to get permission to notify.');
      return null;
    }
  }
};
